/*
Owner: awzy.org
These are parent styles defined by Awzy. 
You can override them, creating your own styles in _custom.scss

Author: Bharani Palani (barani.potshot@gmail.com)

*/
.icon-az {
  color: var(--az-theme-bg-color) !important;
}

.btn-az {
  background: var(--az-theme-bg-color);
  color: var(--az-theme-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:active,
  &:focus,
  &:hover {
    background: var(--az-theme-bg-color);
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5 !important;
  }

  &.dark {
    background: $black-color;
    color: var(--az-theme-bg-color);
  }

  &.sm {
    padding: 8px;
    font-weight: 450;
    border-radius: 5px;
  }

  i {
    font-size: 14px;
  }
}

.hr {
  margin-bottom: 0;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #000000;
}

.brand {
  height: 40px;

  &.global {
    max-width: 200px;
    padding: 5px;
  }

  @include view-mobile {
    // margin-top: 50px;
  }
}

// todo theme setting
.application-wrapper {
  display: grid;
  grid-template-columns: 100%;
  opacity: 0.9;

  .application-content {
    display: flex;

    .fixed-content {
      position: fixed;
      z-index: 1;
      width: 100%;
    }

    &.sideMenuLeft {
      flex-direction: row;
    }

    &.sideMenuRight {
      flex-direction: row-reverse;
    }

    &.topMenu {
      .fixed-content {
        height: auto;
        width: 100%;
      }
    }

    &.sideMenuLeft,
    &.sideMenuRight {
      .fixed-content {
        height: 100vh;
        width: 16.67%;
      }
    }

    .wrapper {
      padding: 10px 0px;
      position: relative;
      overflow: hidden;
      display: block;
      min-height: 100vh;
      @media print {
        width: 100%;
      }

      &.topMenu {
        margin-top: 100px;
        min-height: calc(100vh - 100px);

        @media (max-width: 768px) {
          margin-top: 50px;
        }
      }

      &.sideMenuLeft,
      &.sideMenuRight {
        margin-top: 50px;
        min-height: calc(100vh - 50px);
      }
    }

    .mobile-menu {
      display: none;
    }

    @include view-mobile {
      .mobile-menu {
        display: block;
        background: $black-color;
        width: 100%;
        z-index: 9999;
        .header-menu {
          list-style: none;
          padding-left: 5px;
          min-height: calc(100vh - 100px);

          li {
            padding: 5px 0;

            a {
              text-decoration: none;
            }
          }
        }
      }

      .vertical-header {
        display: none;
      }
    }

    @include view-tablet-potrait {
      .mobile-menu {
        display: none;
      }
    }

    @include view-tablet-landscape {
      .mobile-menu {
        display: none;
      }
    }
  }

  @include view-mobile {
    .application-content {
      flex-direction: column !important;

      .fixed-content {
        width: 100%;
        height: auto;
      }
    }
  }

  @include view-desktop-large {
    &.default {
      grid-template-columns: 100%;
    }

    &.classic {
      grid-template-columns: auto 70% auto;

      .sideMenuLeft {
        .fixed-content {
          width: 11.67%;
        }
      }

      .sideMenuRight {
        .fixed-content {
          width: 11.67%;
        }
      }
    }
  }

  @include view-desktop-x-large {
    &.default {
      grid-template-columns: 100%;
    }

    &.classic {
      grid-template-columns: auto 70% auto;
    }
  }

  // mobile
  @media (max-width: 768px) {
    margin-top: 45px;
  }

  //landscape
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 4px;
  }

  // portrait
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 45px;
  }

  //print
  @media print {
    margin-top: 0;
  }
}

.vertical-header {
  li {
    list-style: none;
  }

  @include view-tablet-potrait {
    padding-top: 0;
  }

  &.topMenu {
    height: 50px;
  }

  .vertical-header-wrapper {
    z-index: 10;
    // scroll impacts topmenu
    // overflow: scroll;
    &.sideMenu {
      height: 100%;
      background: transparent;
    }

    .nav-menu {
      display: flex;

      &.sideMenuLeft,
      &.sideMenuRight {
        flex-direction: column;
        padding: 0 15px;
        height: 100vh;
        margin-top: 45px;
      }

      &.sideMenuRight {
        text-align: right;
      }

      &.topMenu {
        flex-direction: row;
        align-items: center;
        padding-top: 45px;
        @include view-tablet-potrait {
          padding-top: 0;
        }

        .nav-header {
          padding: 5px 10px;
        }

        &.classic {
          @include view-desktop-large {
            width: 70%;
          }
        }
      }

      .nav-header {
        padding: 5px 0;
      }

      .header-menu {
        height: auto;
        overflow: auto;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0px;
        display: flex;
        flex-grow: 1;

        &.sideMenuLeft,
        &.sideMenuRight {
          flex-direction: column;
        }

        &.topMenu {
          flex-direction: row;
          justify-content: space-evenly;
        }

        li {
          font-size: 12px;
          margin: 0 !important;
          text-transform: capitalize;
          white-space: nowrap;

          a {
            display: block;
            width: 100%;
            position: relative;
            text-decoration: none !important;
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }

          &:last-child {
            border-bottom: 0;
          }
        }

        &.sideMenuLeft,
        &.sideMenuRight {
          li {
            padding: 5px;
          }
        }
      }
    }
  }
}

.video-section {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    margin: 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: -3px;
  }

  .banner-img {
    opacity: 0.8;
    object-fit: cover;
  }
}

// for about page
.nameHeading {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
  background: var(--az-theme-color);
  opacity: 0.8;
  padding: 10px 0;

  .skillset {
    color: var(--az-theme-bg-color);
  }

  @media (max-width: 767px) {
    position: fixed;
  }
}

@media (max-width: 767px) {
  .nameHeading {
    left: 0;
    width: 100%;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .nameHeading {
    right: 0%;
    left: auto;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .nameHeading {
    right: 0%;
    left: auto;
  }
}

.videoTag {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}

.globalHeader {
  padding: 5px 15px 0px 5px;
  user-select: none;
  &-dark {
    background-color: rgba(var(--bs-dark-rgb), 0);
  }
  &-light {
    background-color: rgba(var(--bs-white-rgb), 0);
  }
  @media (max-width: 767px) {
    &-dark {
      background-color: rgba(var(--bs-dark-rgb), 0.9);
    }
    &-light {
      background-color: rgba(var(--bs-white-rgb), 0.9);
    }
  }

  .gIcon {
    font-size: 40px;
    cursor: pointer;
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 2px;
  }

  .userImage {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }

  .welcomeText {
    padding: 0 5px;
    font-size: 12px;
    @include ellipsis();
  }

  .labelText {
    font-size: 12px;
    cursor: pointer;
  }

  .social-icons {
    font-size: 14px;
    color: inherit;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown {
    .dropdown-menu {
      padding: 0;

      a {
        display: inline-block;
        padding-left: 0;
        padding-top: 0;
        border-bottom: none;
        font-size: 1.2rem;
        word-break: break-word;
        width: 100%;
        text-decoration: none;
        font-size: 12px;
        padding-right: 0;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
      }
    }

    .dropdown-item {
      padding: 0.5rem 0;
      color: inherit;

      &:first-child {
        padding-top: 10px;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: inherit;
        background-color: inherit;
      }
    }
  }
}

.google {
  button {
    border-radius: 50% !important;
    background: transparent;
    width: 35px;
    height: 35px;
    outline: 0;
    display: inline-block !important;
    box-shadow: none !important;
    font-size: inherit;

    div {
      background: transparent !important;
      padding: 5px !important;
      margin-right: 0 !important;
    }
  }
}

.galleryContainer {
  .leftPane {
    padding-right: 0;
    padding-left: 0;

    .listContainer {
      padding: 0 0 25px 0;
      max-height: 75vh;
      overflow: auto;

      .ul {
        padding-left: 10px;
        list-style: none;

        .li {
          cursor: pointer;
          padding: 2px 0;
        }
      }

      .icon {
        padding-right: 5px;
        color: #aaa;
      }

      .rc-tree {
        font-size: 12px;
        .rc-tree-treenode {
          user-select: none;
          .rc-tree-title {
          }
        }

        .rc-tree-node-selected {
          box-shadow: none;
          background-color: transparent;
          border-left: solid 3px var(--az-theme-bg-color);
          padding-left: 5px;
        }
      }
    }
  }

  .rightPane {
    @include fixedScrollHeight;
    padding: 0;

    .header {
      .breadCrumb {
        display: table;
        align-items: center;

        .breadButton {
          font-size: 12px;
          outline: 0;
          border: 0;
          padding: 2px 10px;
          color: $black-color;
          border-radius: 50px;
          margin: 8px 0;
          white-space: nowrap;
        }

        .breadIcon {
          margin: 3px 9px;
          font-size: 20px;
          vertical-align: middle;
        }
      }
    }

    .viewButtons {
      font-size: 16px;
      cursor: pointer;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .tableGrid {
      .headerGrid {
        padding: 5px 0;
        display: grid;
        grid-template-columns: 60% 40%;
        justify-content: space-between;
        justify-items: stretch;
        align-items: center;
        font-size: 12px;
        .input-group-text {
          max-width: 200px;
          border: 0;
          cursor: pointer;
          @include ellipsis();

          @media (max-width: 768px) {
            max-width: 100px;
          }

          @media (min-width: 768px) and (max-width: 991px) {
            max-width: 100px;
          }

          @media (min-width: 992px) and (max-width: 1199px) {
            max-width: 100px;
          }
        }

        .dirLabel {
          @include ellipsis;
        }
      }

      .gridWrapper {
        font-size: 12px;
        overflow: auto;
        .responsive-gallery-grid {
          display: grid;

          &.table-grid {
            grid-template-columns: repeat(4, 23%);
            column-gap: 20px;
            row-gap: 20px;
            overflow: hidden;
            padding: 10px;
            @media (max-width: 768px) {
              grid-template-columns: repeat(1, 100%);
            }

            @media (min-width: 768px) and (max-width: 991px) {
              grid-template-columns: repeat(2, 48%);
            }

            @media (min-width: 992px) and (max-width: 1199px) {
              grid-template-columns: repeat(2, 49%);
            }
            img {
              width: 100px;
              height: 100px;
              border-radius: 100%;
            }
            .mediaIcon {
              width: 100px;
              height: 100px;
              font-size: 30px;
            }
          }

          &.list-grid {
            grid-template-columns: auto;
            row-gap: 1px;
            @media (max-width: 768px) {
              min-width: 200vw;
            }
            .mediaIcon {
              width: 50px;
              height: 50px;
            }
          }
          .mediaIcon {
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            cursor: pointer;
            svg {
              width: 50px;
              height: 50px;
              cursor: inherit;
            }
          }
          .thumbnail-height {
            display: grid;
            align-items: center;
            margin: auto;
            padding: 10px;
          }

          .copyable {
            padding: 5px;
            display: flex;
            align-items: center;
            column-gap: 5px;
            justify-content: start;
            &.info {
              background: transparent;
            }

            .ellipsis {
              @include ellipsis;
            }
          }

          .child {
            display: grid;

            &:first-child {
              border-left: 0;
            }

            &.table-child {
              grid-template-columns: 100%;
              border-radius: 15px;
              padding: 5px;
              &-dark {
                box-shadow: 0 0 10px 0px $white-color;
              }
              &-light {
                box-shadow: 0 0 10px 0px $grey-color;
              }
            }

            &.list-child {
              grid-template-columns: 10% 30% 30% 30%;
              align-items: center;
              img {
                width: 50px;
                border-radius: 100%;
                height: 50px;
              }
              .thumbnail-height {
                padding: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.videoTag {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
  video {
    object-fit: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
  }
}
.dropZone {
  position: relative;
  font-size: 12px;
  .dropZoneWrapper {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: repeating-radial-gradient(
      circle,
      $black-three,
      $black-three 15px,
      var(--az-theme-bg-color) 15px,
      var(--az-theme-bg-color) 30px
    );
    font-weight: 700;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
    div {
      color: var(--az-theme-color);
      background-color: var(--az-theme-bg-color);
      border-radius: 10px;
      padding: 5px;
    }
  }

  .progress {
    background-color: var(--az-theme-color);
    height: 2px;

    .progress-bar {
      background-color: var(--az-theme-bg-color) !important;
      border-color: var(--az-theme-bg-color) !important;
      height: 2px;
    }
  }

  .upload-error {
    color: $red-color;
  }

  .upload-success {
    color: $green-color;
  }

  .border-success {
    border: dashed 3px $green-color;
    margin: 10px auto;
    width: 95%;
  }

  .border-danger {
    border: dashed 3px $red-color;
    margin: 10px auto;
    width: 95%;
  }

  .progressWrapper {
    padding-top: 5px;

    .gridLabels {
      display: grid;
      grid-template-columns: 80% 20%;

      * {
        @include ellipsis;
      }
    }
  }
}

.wizard {
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
}

.reactive-form {
  textarea {
  }

  .password {
    position: relative;
  }

  .help {
    position: absolute;
    top: 3px;
    right: 3px;
  }

  i {
    cursor: pointer;

    &.eye {
      position: absolute;
      right: 5px;
      top: 35px;
      color: $black-color;
      cursor: pointer;
    }
  }
}

// print options
@media print {
  .col-lg-1,
  .col-md-1,
  .col-sm-1,
  .col-1 {
    width: 8.33;
  }

  .col-lg-2,
  .col-md-2,
  .col-sm-2,
  .col-2 {
    width: 16.67%;
  }

  .col-lg-3,
  .col-md-3,
  .col-sm-3,
  .col-3 {
    width: 25%;
  }

  .col-lg-4,
  .col-md-4,
  .col-sm-4,
  .col-4 {
    width: 33.33%;
  }

  .col-lg-5,
  .col-md-5,
  .col-sm-5,
  .col-5 {
    width: 41.66%;
  }

  .col-lg-6,
  .col-md-6,
  .col-sm-6,
  .col-6 {
    width: 50%;
  }

  .col-lg-7,
  .col-md-7,
  .col-sm-7,
  .col-7 {
    width: 58.33%;
  }

  .col-lg-8,
  .col-md-8,
  .col-sm-8,
  .col-8 {
    width: 66.66%;
  }

  .col-lg-9,
  .col-md-9,
  .col-sm-9,
  .col-9 {
    width: 75%;
  }

  .col-lg-10,
  .col-md-10,
  .col-sm-10,
  .col-10 {
    width: 83.33%;
  }

  .col-lg-11,
  .col-md-11,
  .col-sm-11,
  .col-11 {
    width: 91.66%;
  }

  .col-lg-12,
  .col-md-12,
  .col-sm-12,
  .col-12 {
    width: 100%;
  }

  .print-visible {
    display: block !important;
  }

  .wrapper.toggleOff {
    padding-left: 0;
  }
}

.passwordArea {
  position: relative;

  i {
    position: absolute;
    right: 9px;
    top: 10px;
    color: var(--az-theme-color);
    cursor: pointer;

    &.good {
      color: var(--az-theme-bg-color);
    }

    &.bad {
      color: $red-color;
    }
  }
}

// utils
.cursor-pointer {
  cursor: pointer !important;
}

.relativeSpinner {
  text-align: center;
  padding: 1rem;
  width: 100%;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// scrollbar styles
::-webkit-scrollbar {
  height: 2px;
  width: 3px;
  background: var(--az-theme-color);
}

::-webkit-scrollbar-thumb {
  background: var(--az-theme-bg-color);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: $black-color;
}

:root {
  scrollbar-color: var(--az-theme-bg-color) var(--az-theme-color);
  scrollbar-width: thin !important;
}

.azToaster {
  z-index: 10000;

  .Toastify__toast {
    font-size: 0.8rem;

    &.Toastify__toast--success {
      background: var(--az-theme-bg-color);
      color: var(--az-theme-color);
    }

    color: #fff;

    i {
      &:before {
        margin-left: 0;
        font-size: 16px;
      }

      &.trash {
        position: absolute;
        right: 7px;
      }
    }

    a {
      color: #fff;
      text-decoration: underline !important;
    }
  }
}

.layoutDesign {
  .rc-tree {
    .rc-tree-treenode {
      user-select: none;
    }

    .rc-tree-node-selected {
      box-shadow: none;
      background-color: transparent;
      border-bottom: solid 1px;
    }
  }
}

.modal-content {
  border: 0;
  // background-color: transparent;
  .modal-header {
    display: flex;
    background: var(--app-theme-bg-color);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: none;

    .modal-title {
      font-size: 1rem;
    }

    .close {
      border: 0;
      color: var(--app-theme-color);
      width: 28px;
      height: 28px;
      border-radius: 100%;
    }
  }

  .modal-body {
    .card {
      .card-header {
        .btn {
          padding: 13px 15px;
        }
      }

      &:last-child {
        .card-header {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
    .modalVideo {
      video {
        border-radius: 10px;
      }
    }
  }
}

.facebook-container {
  padding: 0;
  background: transparent;
  border: 0;
}
.offcanvas {
  &.bg-dark {
    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    }
  }
  &.bg-white {
    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    }
  }
}
.propsTypeAhead {
  .rbt-close {
    border: 0;
    background: none;
    margin-top: -0.55rem !important;
  }
  .rbt-menu {
    .dropdown-item {
      padding: 0 5px 0 5px;
      font-size: 14px;
    }
  }
}
