$text-color: #000;
$bg-black: #000;
$grey-color: #aeaeae;
$black-color: #000;
$black-one: #111;
$black-two: #222;
$black-three: #333;
$white-color: #fff;
$border-grey: #ddd;
$red-color: #f35050;
$green-color: #40bd40;